import { AxiosRequest } from "../axiosRequest";

export function getProfile() {
  return AxiosRequest.get("profile");
}

export function postProfile(data) {
  return AxiosRequest.post("profile", data);
}

export function putProfile(data) {
  return AxiosRequest.put("profile", data);
}

export function deleteProfile() {
  return AxiosRequest.delete("profile");
}
