import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { confirmSignUp, forgotPassword, forgotPasswordSubmit, signIn, signUp } from "../../api/authentication";

import { windowStore } from "../../utils/WindowStore";
import { ACCESS_TOKEN, ID_TOKEN, USER_DETAILS } from "../../constants/common";
import { getUserDetails, postUserDetails } from "../../api/misc";
import { formatUserDetails } from "../../utils/commonFunctions";
import { sendWelcomeEmail } from "../../api/notifications";

export const authenticateSignUp = createAsyncThunk("signUp", async (data, { rejectWithValue }) => {
  try {
    const { email, password } = data;
    const response = await signUp({ email, password, attributes: { email } });
    return { response, data };
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const authenticateSignupVerify = createAsyncThunk(
  "verifySignUp",
  async ({ email, code, data }, { rejectWithValue }) => {
    try {
      const response = await confirmSignUp({ email, code });
      const signInResponse = await signIn({ email, password: data.password });
      const { signInUserSession } = signInResponse;
      const accessToken = signInUserSession.accessToken.jwtToken;
      const idToken = signInUserSession.idToken.jwtToken;
      windowStore.setToken(ACCESS_TOKEN, accessToken);
      windowStore.setToken(ID_TOKEN, idToken);

      await postUserDetails(data);
      await sendWelcomeEmail(email);
      return response;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const authenticateSignIn = createAsyncThunk("signIn", async ({ email, password }, { rejectWithValue }) => {
  try {
    const response = await signIn({ email, password });

    const { signInUserSession } = response;
    const accessToken = signInUserSession.accessToken.jwtToken;
    const idToken = signInUserSession.idToken.jwtToken;

    windowStore.setToken(ACCESS_TOKEN, accessToken);
    windowStore.setToken(ID_TOKEN, idToken);

    return response;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const authenticateForgotPassword = createAsyncThunk("forgotPassword", async (email, { rejectWithValue }) => {
  try {
    const response = await forgotPassword({ email });
    return response;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const authenticateForgotPasswordSubmit = createAsyncThunk(
  "forgotPasswordSubmit",
  async ({ email, code, newPassword }, { rejectWithValue }) => {
    try {
      return await forgotPasswordSubmit({ email, code, newPassword });
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const refreshUserDetails = createAction("refreshUserDetails", () => {
  const userDetails = formatUserDetails(windowStore.getItem(USER_DETAILS) ?? "{}");
  return {
    payload: { ...userDetails },
  };
});

export const fetchUserDetails = createAsyncThunk("fetchUserDetails", async (_, { rejectWithValue }) => {
  try {
    return await getUserDetails();
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const logout = createAction("logout", () => {
  windowStore.removeToken(ACCESS_TOKEN);
  windowStore.removeToken(ID_TOKEN);
  return { payload: { authenticated: false } };
});

export const clearErrors = createAction("clearErrors");

export const clearEmailVerifcation = createAction("clearEmailVerifcation");
