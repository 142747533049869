import { Box, CardMedia } from "@mui/material";
import React from "react";

export function LoaderProgress() {
  return (
    <Box
      sx={{
        height: "100vh",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CardMedia
        component="img"
        alt="MShift Logo"
        image="/calendar-gif.gif"
        title="MShift Logo"
        sx={{
          height: 100,
          width: 100,
          objectFit: "contain",
        }}
      />
    </Box>
  );
}
