import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAppliedShifts, getUnits, approveShift, assignShift, getSwapShifts } from "../../api/facilities/home";

export const getUnitsAction = createAsyncThunk(
  "facilities/getUnits",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getUnits();
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
  {
    condition(_, { getState }) {
      return !getState().facilitiesReducer.isUnitsLoaded;
    },
  }
);

export const getAppliedShiftsAction = createAsyncThunk(
  "facilities/getAppliedShifts",
  async (id, { rejectWithValue }) => {
    try {
      return await getAppliedShifts(id);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
  // {
  //   condition(_, { getState }) {
  //     return !getState().facilitiesReducer.isShiftsLoaded;
  //   },
  // }
);
export const approveShiftAction = createAsyncThunk("facilites/approveShiftAction", async (id, { rejectWithValue }) => {
  try {
    return await approveShift(id);
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const assignShiftAction = createAsyncThunk("approveShiftAction", async (id, { rejectWithValue }) => {
  try {
    return await assignShift(id);
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const getSwapShiftsAction = createAsyncThunk(
  "facilities/getSwapShiftsAction",
  async (type, { rejectWithValue }) => {
    try {
      const response = await getSwapShifts(type);
      return { ...response, type };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
