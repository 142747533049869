/* eslint-disable camelcase */
import { createSlice } from "@reduxjs/toolkit";
import {
  authenticateSignUp,
  logout,
  refreshUserDetails,
  authenticateSignupVerify,
  authenticateSignIn,
  fetchUserDetails,
} from "../actions";
import {
  authenticateForgotPassword,
  authenticateForgotPasswordSubmit,
  clearErrors,
  clearEmailVerifcation,
} from "../actions/authenticationActions";
import { windowStore } from "../../utils/WindowStore";
import { ACCESS_TOKEN, ID_TOKEN } from "../../constants/common";
import { formatUserDetails } from "../../utils/commonFunctions";

const accessToken = windowStore.getToken(ACCESS_TOKEN);
const idToken = windowStore.getToken(ID_TOKEN);

const initialState = {
  authenticated: Boolean(accessToken && idToken),
  signedUp: false,
  verified: false,
  isLoading: false,
  userName: null,
  isUserDetailsLoaded: false,
  authenticationError: null,
  otpAuthenticationError: null,
  signupData: null,
  authenticateForgotPasswordRequestSuccess: false,
  userDetails: {
    userId: null,
    firstName: "",
    lastName: "",
    phone: "",
    licenseVerificationStatus: "",
    certVerificationStatus: "",
    isProfessionalType: true,
    isCertified: false,
    hasLicense: false,
    isPreferenceComplete: false,
    isProfileComplete: false,
    isReferenceComplete: false,
    isWorkExperienceComplete: false,
    isCredentialsComplete: false,
  },
};

const authenticationReducer = createSlice({
  name: "authenticationReducer",
  initialState,
  reducers: {},
  extraReducers: {
    [logout]: (state) => {
      state.authenticated = false;
    },
    [clearErrors]: (state) => {
      state.otpAuthenticationError = null;
    },
    [clearEmailVerifcation]: (state) => {
      state.authenticateForgotPasswordRequestSuccess = false;
    },
    [authenticateSignUp.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.signedUp = true;
      state.userName = action.payload.response.user.username;
      state.userInfo = action.payload.response;
      state.signupData = action.payload.data;
    },
    [authenticateSignUp.pending]: (state) => {
      state.isLoading = true;
    },
    [authenticateSignUp.rejected]: (state, action) => {
      state.isLoading = false;
      state.authenticationError = action.payload;
    },
    [authenticateSignupVerify.fulfilled]: (state) => {
      state.isLoading = false;
      state.verified = true;
    },
    [authenticateSignupVerify.pending]: (state) => {
      state.isLoading = true;
    },
    [authenticateSignupVerify.rejected]: (state, action) => {
      state.isLoading = false;
      state.authenticationError = action.payload;
    },
    [authenticateSignIn.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.authenticated = true;
      state.userName = action.payload.attributes.email;
    },
    [authenticateSignIn.pending]: (state) => {
      state.isLoading = true;
    },
    [authenticateSignIn.rejected]: (state, action) => {
      state.isLoading = false;
      state.authenticationError = action.payload;
    },
    [authenticateForgotPassword.fulfilled]: (state) => {
      state.isLoading = false;
      state.authenticateForgotPasswordRequestSuccess = true;
    },
    [authenticateForgotPassword.pending]: (state) => {
      state.isLoading = true;
    },
    [authenticateForgotPassword.rejected]: (state) => {
      state.isLoading = false;
    },
    [authenticateForgotPasswordSubmit.fulfilled]: (state) => {
      state.isLoading = false;
      state.authenticateForgotPasswordRequestSuccess = false;
    },
    [authenticateForgotPasswordSubmit.pending]: (state) => {
      state.isLoading = true;
    },
    [authenticateForgotPasswordSubmit.rejected]: (state, action) => {
      state.isLoading = false;
      state.otpAuthenticationError = action.payload;
    },
    [refreshUserDetails]: (state, action) => {
      state.userName = action.payload.name;
      state.isLoading = false;
    },
    [fetchUserDetails.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.userDetails = formatUserDetails(action.payload);
      state.isUserDetailsLoaded = true;
    },
    [fetchUserDetails.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchUserDetails.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export default authenticationReducer;
