export const baseTheme = {
  palette: {
    assignment: {
      current: "#2ACBBA",
      pending: "#F9CA67",
      rejected: "#FF7285",
      main: "#45AAF2",
    },
    primary: {
      main: "#45AAF2",
      dark: "#1565c1",
    },
    secondary: {
      main: "#A5B1C2",
    },
    background: {
      paper: "#FFF",
      main: "#FFF",
      gray: "#383E45",
      primary: "#45AAF2",
      secondary: "#F3F7FA",
      appbar: "#F3F7FA",
      statusbar: "#97ADDE19",
      modal: "#EEF2F5",
    },
    text: {
      gray: "#707070",
      black: "#343434",
      white: "#FFFFFF",
      blue: "#294278",
      primary: "#45AAF2",
      secondary: "#FFFFFF",
      ternary: "#666666",
      current: "#000000",
      pending: "#000000",
      rejected: "#FFFFFF",
      main: "#FFFFFF",
    },
    action: {
      active: "#001E3C",
    },
    yellow: {
      main: "#FFCA28",
      secondary: "#fcc41c",
    },
    warning: {
      main: "#F9CF75",
    },
    icon: {
      main: "#000",
      defined: "#070707",
    },
    green: { main: "#22BF6B" },
    components: {
      rating: "#02a8a8",
    },
    black: "#000",
    blue: "#45AAF2",
  },
  typography: {
    fontSize: {
      xsmall: "0.5rem",
      small: "0.75rem",
      medium: "1rem",
      large: "1.25rem",
      xlarge: "1.5rem",
      xxlarge: "2rem",
      xxxlarge: "4rem",
      sm4: "0.5rem",
      sm3: "0.625rem",
      sm2: "0.750rem",
      sm1: "0.875rem",
      md: "1rem",
      lg1: "1.125rem",
      lg2: "1.250rem",
      lg3: "1.5rem",
      lg4: "2rem",
      lg5: "4rem",
      lg6: "8rem",
    },
  },
  layout: {
    xsmall: "4px",
    small: "8px",
    medium: "12px",
    large: "16px",
    xlarge: "20px",
    borderRadius: "14px",
    borderRadiusLarge: "24px",
    success: {
      main: "#2ACBBA",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1440,
      xl: 1920,
      mobile: 0,
      desktop: 600,
    },
  },
};

export const asttheme = {
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: "#db3131",
          "&$error": {
            color: "#db3131",
          },
        },
      },
    },
  },
};
