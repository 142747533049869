import { AxiosRequest } from "../axiosRequest";

const URL = "prof-references";

export function getReferences() {
  return AxiosRequest.get(URL);
}

export function postReferences(data) {
  return AxiosRequest.post(URL, data);
}

export function putReferences(data) {
  return AxiosRequest.put(URL, data);
}

export function deleteReferences(data) {
  return AxiosRequest.delete(URL, { data });
}
