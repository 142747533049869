import { createSlice } from "@reduxjs/toolkit";
import {
  authenticateSignUp,
  authenticateSignupVerify,
  clearRedirect,
  redirectToUrl,
  setAccountType,
  getLookup,
} from "../actions";
import { routes } from "../../pages/root/routes";
import { AUTH_STAGES } from "../constants";
import { authenticateForgotPasswordSubmit } from "../actions/authenticationActions";

const initialState = {
  redirectTo: null,
  accountType: null,
  isLookupLoaded: false,
  lookupTable: {},
};

const commonReducer = createSlice({
  name: "commonReducer",
  initialState,
  reducers: {},
  extraReducers: {
    [redirectToUrl]: (state, action) => {
      state.redirectTo = action.payload.redirectTo;
    },
    [clearRedirect]: (state) => {
      state.redirectTo = null;
    },
    [setAccountType]: (state, action) => {
      state.accountType = action.payload.accountType;
    },
    [authenticateSignUp.fulfilled]: (state) => {
      state.redirectTo = `${routes.SIGN_UP}${AUTH_STAGES.otp}`;
    },
    [authenticateSignupVerify.fulfilled]: (state) => {
      state.redirectTo = `${routes.SIGN_UP}${AUTH_STAGES.congratulation}`;
    },
    [getLookup.fulfilled]: (state, action) => {
      state.lookupTable = action.payload || {};
      state.isLookupLoaded = true;
    },
    [authenticateForgotPasswordSubmit.fulfilled]: (state) => {
      state.isLoading = false;
      // state.redirectTo = routes.SIGN_IN;
    },
  },
});

export default commonReducer;
