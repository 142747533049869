import { configureStore as configureToolkitStore } from "@reduxjs/toolkit";
import rootReducer from "./reducers";

// use redux dev tools in development
// const useReduxDevTools = Boolean(process.env.NODE_ENV === "development" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__);

export function configureStore(initialState = {}) {
  return configureToolkitStore({
    reducer: rootReducer,
    preloadedState: initialState,
    devTools: true,
  });
}
