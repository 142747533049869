export const routes = {
  ROOT: "/",
  SIGN_IN: "/signin",
  SIGN_UP: "/signup",
  FORGOT_PASSWORD: "/forgot-password",
  HOME: "/home",
  LOGOUT: "/logout",
  PAYMENTS: "/payments",
  PROFILE: "/profile",
  MY_SHIFTS: "/myshifts",
  INBOX: "/inbox",
  ASSIGNMENT_INFO: "/assignment-info",
  ASSIGNMENT_DETAILS: "/assignment-details",
  FACILITIES_HOME: "/facility/home",
  FACILITIES_SHIFTS: "/facility/shifts",
  LEARN_MORE: "http://www.equitek.info/myshift",
};
