import { AxiosRequest } from "./axiosRequest";

export function getNotificationList({ pageNo, query, type }) {
  const apiString = query
    ? `notifications?type=${type}&query=${query}size=${process.env.REACT_APP_PAGINATION_LIMIT}&page=${pageNo}`
    : `notifications?type=${type}&size=${process.env.REACT_APP_PAGINATION_LIMIT}&page=${pageNo}`;
  return AxiosRequest.get(apiString);
}

export function sendWelcomeEmail(email) {
  return AxiosRequest.get(`/signup-notification?email=${email}`);
}
