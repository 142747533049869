import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCalendarShifts, getCalendarSwapShifts, getShift, getShifts, getSwapShiftsByType } from "../../api/shifts";
import { getShiftDefinitions } from "../../api/misc";

export const getShiftsAction = createAsyncThunk(
  "shifts/getShifts",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getShifts();
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
  {
    condition(_, { getState }) {
      return !getState().shiftsReducer.isShiftLoading;
    },
  }
);

export const getShiftAction = createAsyncThunk(
  "shifts/getShift",
  async (id, { rejectWithValue }) => {
    try {
      const response = await getShift(id);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
  // {
  //   condition(_, { getState }) {
  //     return !getState().shiftsReducer.isShiftLoaded;
  //   },
  // }
);

export const getCalendarShiftsAction = createAsyncThunk(
  "shifts/getCalendarShifts",
  async ({ year, month }, { rejectWithValue }) => {
    try {
      const response = await getCalendarShifts({ year, month });
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
  // {
  //   condition(_, { getState }) {
  //     return !getState().shiftsReducer.isCalendarShiftLoaded && !getState().shiftsReducer.isCalendarShiftLoading;
  //   },
  // }
);

export const getCalendarSwapShiftsAction = createAsyncThunk(
  "shifts/getCalendarSwapShiftsAction",
  async ({ year, month }, { rejectWithValue }) => {
    try {
      const response = await getCalendarSwapShifts({ year, month });
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getSwapShiftsByTypeAction = createAsyncThunk(
  "shifts/getSwapShiftsByType",
  async (type, { rejectWithValue }) => {
    try {
      const response = await getSwapShiftsByType(type);
      return { response, type };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getShiftDefinitionsAction = createAsyncThunk(
  "shifts/getShiftDefinitions",
  async (query, { rejectWithValue }) => {
    try {
      const response = await getShiftDefinitions(query);
      return response?.data ?? [];
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateShiftsDefinitionFilterAction = createAction("shifts/updateShiftsDefinitionFilter", (filter) => ({
  payload: filter,
}));
