import { createSlice } from "@reduxjs/toolkit";
import {
  approveShiftAction,
  getAppliedShiftsAction,
  assignShiftAction,
  getSwapShiftsAction,
  getUnitsAction,
} from "../actions/facilitiesActions";

const initialState = {
  units: [],
  stats: {},
  isUnitsLoaded: false,
  isUnitsLoading: false,
  shifts: null,
  isShiftsLoaded: false,
  isShiftsLoading: false,
  isApproveShiftLoaded: false,
  isApproveShiftLoading: false,
  isApproved: false,
  isAssignShiftLoaded: false,
  isAssignShiftLoading: false,
  isAssigned: false,
  isSwapShiftsLoaded: false,
  isSwapShiftsLoading: false,
  swapShifts: [],
};

const facilitiesReducer = createSlice({
  name: "facilitiesReducer",
  initialState,
  reducers: {},
  extraReducers: {
    [getUnitsAction.fulfilled]: (state, action) => {
      state.isUnitsLoaded = true;
      state.isUnitsLoading = false;
      state.units = action.payload.body && action.payload.body.shift_details;
      state.stats = action.payload.body && action.payload.body.shift_candidate_status_details;
    },
    [getUnitsAction.pending]: (state) => {
      state.isUnitsLoading = true;
    },
    [getUnitsAction.rejected]: (state) => {
      state.isUnitsLoading = false;
    },
    [getAppliedShiftsAction.fulfilled]: (state, action) => {
      state.isShiftsLoaded = true;
      state.isShiftsLoading = false;
      if (action.payload && action.payload.statusCode === 200) state.shifts = action.payload.body;
      else state.shifts = null;
      state.isApproved = false;
    },
    [getAppliedShiftsAction.pending]: (state) => {
      state.shifts = null;
      state.isShiftsLoading = true;
    },
    [getAppliedShiftsAction.rejected]: (state) => {
      state.isShiftsLoading = false;
    },
    [approveShiftAction.fulfilled]: (state) => {
      state.isApproveShiftLoaded = true;
      state.isApproveShiftLoading = false;
      state.isApproved = true;
    },
    [approveShiftAction.pending]: (state) => {
      state.isApproveShiftLoading = true;
      state.isApproveShiftLoaded = false;
      state.isApproved = false;
    },
    [approveShiftAction.rejected]: (state) => {
      state.isApproveShiftLoaded = true;
      state.isApproveShiftLoading = false;
      state.isApproved = false;
    },
    [assignShiftAction.fulfilled]: (state) => {
      state.isAssignShiftLoaded = true;
      state.isAssignShiftLoading = false;
      state.isAssigned = true;
    },
    [assignShiftAction.pending]: (state) => {
      state.isAssigneShiftLoading = true;
      state.isAssignShiftLoaded = false;
      state.isAssigned = false;
    },
    [assignShiftAction.rejected]: (state) => {
      state.isAssignShiftLoaded = true;
      state.isAssignShiftLoading = false;
      state.isAssigned = false;
    },
    [getSwapShiftsAction.fulfilled]: (state, action) => {
      state.isSwapShiftsLoaded = true;
      state.isSwapShiftsLoading = false;
      state.swapShifts = action.payload &&
        action.payload.body && { swaps: action.payload.body, type: action.payload.type };
    },
    [getSwapShiftsAction.pending]: (state) => {
      state.isSwapShiftsLoading = true;
      state.isApproveShiftLoaded = false;
      state.swapShifts = [];
    },
    [getSwapShiftsAction.rejected]: (state) => {
      state.isApproveShiftLoaded = true;
      state.isSwapShiftsLoaded = false;
    },
  },
});

export default facilitiesReducer;
