import { useEffect } from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { routes } from "./routes";
import { currentSession } from "../../api/authentication";

function PrivateRoute({ component: Component, authenticated, ...rest }) {
  const checkLoggedIn = async () => {
    const isLoggedIn = authenticated;
    if (isLoggedIn === true) {
      await currentSession();
    }
  };

  useEffect(() => {
    checkLoggedIn();
  }, [Component]);

  return (
    <Route
      {...rest}
      render={(props) =>
        authenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: routes.SIGN_IN,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

PrivateRoute.defaultProps = {
  authenticated: false,
  component: null,
  location: null,
};

PrivateRoute.propTypes = {
  authenticated: PropTypes.bool,
  component: PropTypes.elementType,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

export default PrivateRoute;
