import { AxiosRequest } from "../axiosRequest";

const URL = "prof-preferences";

export function getPreferences() {
  return AxiosRequest.get(URL);
}

export function postPreferences(data) {
  return AxiosRequest.post(URL, data);
}

export function putPreferences(data) {
  return AxiosRequest.put(URL, data);
}

export function deletePreferences(id) {
  return AxiosRequest.delete(URL);
}
