import moment from "moment";

export function retry(fn, retriesLeft = 5, interval = 250) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error);
            return;
          }
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}

/**
 *
 * @param dateString
 * @returns {string}
 *
 * @example
 *
 * const dateString = "2020-01-01T00:00:00.000Z";
 * const formattedDate = formatDateToMonthDay(dateString);
 * console.log(formattedDate); // "Jan 1"
 */
export function formatDateToMonthDay(dateString) {
  const date = new Date(dateString);
  const options = { month: "short", day: "numeric" };
  const formattedDate = date.toLocaleDateString("en-US", options);
  return formattedDate;
}

/**
 * @param date
 * @returns {string}
 *
 * @example
 * const date = new Date();
 * const formattedDate = formatDateToMonthDayYear(date);
 * console.log(formattedDate); // "Jan 1 (Monday)"
 */
export function formatDateToMonthDateDay(date) {
  // Format date to Dec 12 (Monday)
  date = new Date(date);
  const day = date.toLocaleString("en-US", { weekday: "long" });
  const month = date.toLocaleString("en-US", { month: "short" });
  const dayNum = date.toLocaleString("en-US", { day: "numeric" });
  return `${month} ${dayNum} (${day})`;
}

export const formatDateToHours = (date) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  hours = hours < 10 ? `0${hours}` : hours;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  return `${hours}:${minutes}`;
};

const ordinalSuffixOf = (i) => {
  const j = i % 10;
  const k = i % 100;
  if (j === 1 && k !== 11) {
    return `${i}st`;
  }
  if (j === 2 && k !== 12) {
    return `${i}nd`;
  }
  if (j === 3 && k !== 13) {
    return `${i}rd`;
  }
  return `${i}th`;
};
const shortNameMonth = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const prependZero = (value) => {
  if (value < 10) return `0${value}`;
  return value;
};
export function formatDateApp(value, type = null, debug = false) {
  if (!value) {
    return "";
  }
  const d = new Date(value);
  const day = d.getUTCDay();
  const date = d.getUTCDate();
  const month = d.getUTCMonth();
  const year = d.getUTCFullYear();

  let returnValue = "";

  switch (type) {
    case "dt M Y":
      // if (day === 1) day = `${day}st`
      // else if (day === 2) day = `${day}nd`
      // else if (day === 3) day = `${day}rd`
      // else day = `${day}th`;
      returnValue = `${ordinalSuffixOf(date)} ${shortNameMonth[month]} ${year}`;
      break;
    case "M Y":
      returnValue = `${shortNameMonth[month]} ${year}`;
      break;
    case "M Dt":
      returnValue = `${shortNameMonth[month]} ${ordinalSuffixOf(date)}`;
      break;
    case "M D":
      returnValue = `${shortNameMonth[month]} ${prependZero(date)}`;
      break;
    case "M d (D)":
      returnValue = `${shortNameMonth[month]} ${prependZero(date)} (${weekday[day]})`;
      break;
    case "d/m/y":
      returnValue = `${prependZero(date)}/${prependZero(month + 1)}/${`${year}`.slice(2)}`;
      break;
    case "m/d/y":
      returnValue = `${prependZero(month + 1)}/${prependZero(date)}/${`${year}`.slice(2)}`;
      break;
    case "m/d/Y":
      returnValue = `${prependZero(month + 1)}/${prependZero(date)}/${year}`;
      break;
    case "M Dt, Y (D)":
      returnValue = `${months[month]} ${ordinalSuffixOf(date)}, ${year} (${weekday[day]})`;
      break;
    case "D d, M Y":
      returnValue = `${weekday[day]} ${prependZero(date)}, ${months[month]} ${year} `;
      break;

    default:
      break;
  }
  return returnValue;
}

export function formatTimeApp(time) {
  if (!time) return "";
  let [h] = time.split(":");
  const ampm = h >= 12 ? "PM" : "AM";

  h %= 12;
  h = h || 12;

  return `${Number(h)} ${ampm}`;
}

export const formatUserDetails = (details = {}) => {
  const {
    user_id,
    first_name,
    last_name,
    phone_nbr,
    user_type_lkup_desc,
    flag_certify,
    flag_license,
    flag_preference,
    flag_profile_complete,
    flag_profile_info,
    flag_reference,
    flag_wrkexp,
    flag_education,
    org_name,
    orig_id,
    profession_lkup_desc,
    user_name,
    flag_license_verification,
    flag_cert_verification,
    flag_emr,
  } = details;

  return {
    userId: user_id,
    firstName: first_name,
    lastName: last_name,
    phone: phone_nbr,
    isProfessionalType: user_type_lkup_desc === "Professional",
    isCertified: flag_certify,
    licenseVerificationStatus: flag_license_verification,
    certVerificationStatus: flag_cert_verification,
    hasLicense: flag_license,
    isPreferenceComplete: flag_preference,
    isProfileInfoComplete: flag_profile_info,
    isProfileComplete: flag_profile_complete,
    isReferenceComplete: flag_reference,
    isWorkExperienceComplete: flag_wrkexp,
    isCredentialsComplete: flag_education && flag_certify && flag_license && flag_emr,
    profession: profession_lkup_desc,
    userName: user_name,
    org_name,
    orig_id,
    ...details,
  };
};
