import Axios from "axios";
import axiosRetry from "axios-retry";
import { windowStore } from "../utils/WindowStore";
import { ACCESS_TOKEN, ID_TOKEN } from "../constants/common";

export const AxiosRequest = Axios.create({
  baseURL:
    process.env.REACT_APP_STAGE === "uat"
      ? "https://8p450ugg8f.execute-api.us-east-1.amazonaws.com/uat/"
      : "https://8p450ugg8f.execute-api.us-east-1.amazonaws.com/Dev/",
  timeout: 30000,
  responseType: "json",
  withCredentials: false,
  headers: {
    "Content-Type": "application/json",
  },
});
console.log(process.env.REACT_APP_STAGE, "sasasasa");
/**
 * This wrapper will retry any http-request that fails due to network/server errors.
 */
axiosRetry(AxiosRequest, {
  retries: 4,
  retryCondition: (error) =>
    !(error && error.message === "Network Error" && error.config && error.config.url.includes("lookup.")),
  retryDelay: () => 1000,
});

AxiosRequest.interceptors.request.use(
  (config) => {
    const idToken = windowStore.getToken(ID_TOKEN);
    const accessToken = windowStore.getToken(ACCESS_TOKEN);

    config.headers.Authorization = idToken;
    if (accessToken) {
      config.headers.accesstoken = accessToken;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

AxiosRequest.interceptors.response.use(
  (response) => {
    if (response?.data?.body && typeof response?.data?.body === "string") {
      try {
        return JSON.parse(response.data.body);
      } catch (e) {
        return response.data;
      }
    }
    if (response?.data?.body) {
      return response.data.body;
    }
    return response.data;
  },
  (error) => {
    const errorResponse = error.response;
    if (errorResponse) {
      console.log("response error", errorResponse);
    }

    if (errorResponse.status === 401) {
      window.location = "/logout";
    }

    if (error.message === "Network Error") {
      return Promise.reject(error.toJSON());
    }

    if (errorResponse) {
      let errorData = {};
      if (typeof errorResponse.data === "string") {
        errorData.message = errorResponse.data;
      } else if (errorResponse.data === null) {
        errorData.message = "Error";
      } else if (errorResponse.data.apiResult) {
        errorData = { ...errorResponse.data.apiResult };
      } else {
        errorData = { ...errorResponse.data };
      }
      return Promise.reject(errorData);
    }
    return Promise.reject(error);
  }
);
