import { Amplify, Auth } from "aws-amplify";
import awsConfig from "../configurations/aws/aws-amplify";
import { windowStore } from "../utils/WindowStore";
import { ACCESS_TOKEN, ID_TOKEN, REFRESH_TOKEN } from "../constants/common";

Amplify.configure(awsConfig);

export function signUp({ email, password, attributes = {} }) {
  return Auth.signUp({ username: email, password, attributes });
}

export async function confirmSignUp({ email, code }) {
  return Auth.confirmSignUp(email, code);
}

export async function signIn({ email, password }) {
  return Auth.signIn(email, password);
}

export async function forgotPassword({ email }) {
  return Auth.forgotPassword(email);
}

export async function forgotPasswordSubmit({ email, code, newPassword }) {
  return Auth.forgotPasswordSubmit(email, code, newPassword);
}

export async function currentSession() {
  const session = await Auth.currentSession();
  const accessToken = session.accessToken.jwtToken;
  const idToken = session.idToken.jwtToken;
  const refreshToken = session.refreshToken.jwtToken;
  windowStore.setToken(ACCESS_TOKEN, accessToken);
  windowStore.setToken(ID_TOKEN, idToken);
  windowStore.setToken(REFRESH_TOKEN, refreshToken);
  return {
    accessToken: session.accessToken.jwtToken,
    idToken: session.idToken.jwtToken,
    refreshToken: session.refreshToken.jwtToken,
  };
}
